import getDefaultCmsTransport from '../../../../services/api/cmsInterceptor'
import logProviderFactory from '../../../../utils/logs/logProviderFactory'
import { getCookie } from '../../../../utils/cookie'
import {
  CART_COLLECTION_TYPE,
  IApiGetCartResponse,
  ICartCollection,
  ICartItem,
} from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'

const ENDPOINTS = {
  ADD_ITEMS: 'carts/addItems',
  CART_COLLECTIONS: 'cartcollections',
}

export interface IApiGetCartCollectionResponse {
  error: string
  data: ICartCollection[] | null
}

const getSavedCartCollection = async (cartEmail: string): Promise<IApiGetCartCollectionResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}?email=${cartEmail}&type=${CART_COLLECTION_TYPE.SAVE_FOR_LATER}`
    const response = await getDefaultCmsTransport(false).get(endpoint)
    logProviderFactory.logMessage(`Success in getSavedCartCollection() for email: ${cartEmail}.`)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    logProviderFactory.logError(`Error in getSavedCartCollection() for email: ${cartEmail}.`, e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const saveCartCollection = async (cartEmail: string, cartItems: ICartItem[]): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}`
    const response = await getDefaultCmsTransport(false).post(endpoint, {
      type: CART_COLLECTION_TYPE.SAVE_FOR_LATER,
      email: cartEmail,
      items: mapItemsToSave(cartItems),
      uiCartId: getCookie('anonId', null) || '',
    })
    logProviderFactory.logMessage(`Success in saveCartCollection() for email: ${cartEmail}.`)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    logProviderFactory.logError(`Error in saveCartCollection() for email: ${cartEmail}.`, e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const mapItemsToSave = (cartItems: ICartItem[]) => {
  return cartItems.map((item: ICartItem) => {
    return {
      metaData: item.metaData,
      quantity: item.quantity,
      product: {
        sku: item.product.sku,
        id: item.product.id,
      },
    }
  })
}

const updateSavedCartCollection = async (
  id: string,
  cartEmail: string,
  cartItems: ICartItem[],
): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}/${id}`
    const response = await getDefaultCmsTransport().put(endpoint, {
      type: CART_COLLECTION_TYPE.SAVE_FOR_LATER,
      email: cartEmail,
      items: mapItemsToSave(cartItems),
    })
    logProviderFactory.logMessage(`Success in updateSavedCartCollection() for email: ${cartEmail}.`)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    logProviderFactory.logError(`Error in updateSavedCartCollection() for email: ${cartEmail}.`, e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const deleteSavedCartCollection = async (id: string): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}/${id}`
    const response = await getDefaultCmsTransport(false).delete(endpoint)
    logProviderFactory.logMessage(`Success in deleteSavedCartCollection() for email: ${id}.`)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    logProviderFactory.logError(`Error in deleteSavedCartCollection() for id: ${id}.`, e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  updateSavedCartCollection,
  getSavedCartCollection,
  deleteSavedCartCollection,
  saveCartCollection,
}
