import React, { useEffect } from 'react'
import { CYLINDO_ACCOUNT_ID, CYLINDO_VIEWER_ID, HYPHEN_REGEX } from '../../../../../settings/variables'
import {
  IProductImage,
  IProductImageType,
} from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { IMorUnbxdProduct } from '../../../../Utils/unbxdUtils'
import { IActiveOptions, ICustomAddons, IPDPProduct } from '../PDP'
import queryString from 'query-string'
import {
  cylindoCodes,
  getCustomProductColorValue,
  getCustomProductCylindoMaterialCd,
} from '../../../../Utils/customProductUtils'
import { IAttributeSwatch } from '../Swatches/Swatches'

export const isImage360Viewer = (product: IMorUnbxdProduct) => {
  return product?.IMAGE360 === 'Y'
}

interface ICylindoViewerProps {
  product: IMorUnbxdProduct
  options: IActiveOptions[]
  isFacingOptionChanging: boolean
  onFinishInitViewer: () => void
}

interface ICylindoViewerOpts {
  accountID: number
  productCode: string
  containerID: string
  features?: string[]
  alternateContent?: IAlternateContent[]
  frames: number[]
  thumbPageSize: number
  thumbCount: number
  alternateContentZoom: boolean
  tooltipAltImgText: string | null
  thumbLocation: 'top' | 'bottom' | 'left' | 'right'
}

interface IAlternateContent {
  thumb: string
}

interface IImageAlternateContent extends IAlternateContent {
  image: string
}

interface IVideoAlternateContent extends IAlternateContent {
  provider: string
  description: string
  poster: string
  sources: {
    src: string
    type: string
  }[]
}

export interface ICylindoViewerInstance {
  setFeatures: (features: string[]) => void
  updateAlternateContent: (alternateContent: IAlternateContent[]) => void
}

// We can modify Cylindo features with COLOR and ACCENT_PILLOW for Custom products.
export enum ICylindoCustomFeatureCodes {
  COLOR = 'COLOR',
  ACCENT_PILLOW = 'ACCENT_PILLOW',
  ACCENT_PILLOW2 = 'ACCENT_PILLOW2',
  ACCENT_PILLOW3 = 'ACCENT_PILLOW3',
}

export const supportedFeatureCodes = [
  'COLOR',
  'FACING',
  'MOTION_TYPE',
  'ADJ_FOUNDATION',
  'BED_FOUND_NEED',
  'BED_SIZE',
  'MATTRESS_COMFORT_LVL',
  'POWER_TYPE',
  'SEAT_BACK_TYPE',
  'SEAT_COMP',
  'SIZE_CLASS',
  'STORAGE_COMPARTMENT',
  'STORAGE_TYPE',
  'TABLE_HEIGHT_CLASS',
  'TABLE_SHAPE',
  'TV_SIZE',
]
export const allowedFeaturesOnQueryParams = ['body_color', 'accent_color', 'accent_color2', 'accent_color3']

export const getStandardProductFeatures = (options: IActiveOptions[], product?: IMorUnbxdProduct) => {
  const features: string[] = []
  const cylindoMaterialCode = product?.CYLINDO_MATERIAL_CD

  options.forEach((element: IActiveOptions) => {
    if (element.code && supportedFeatureCodes.includes(element.code)) {
      const featureValue =
        element.code === supportedFeatureCodes[0] && cylindoMaterialCode ? cylindoMaterialCode : element.value
      features.push(element.code)
      features.push(featureValue.toUpperCase())
    }
  })

  if (product?.ACCENT_COLOR_STOCK_SKU) {
    const customProductCylindoMaterialCode = getCustomProductCylindoMaterialCd(product)
    const featureValue = customProductCylindoMaterialCode
      ? getCustomProductColorValue(customProductCylindoMaterialCode, product.ACCENT_COLOR_STOCK_SKU)
      : product.ACCENT_COLOR_STOCK_SKU
    features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW)
    features.push(featureValue.toUpperCase())
  }

  if (product?.ACCENT_COLOR_STOCK_SKU2) {
    const customProductCylindoMaterialCode = getCustomProductCylindoMaterialCd(product)
    const featureValue = customProductCylindoMaterialCode
      ? getCustomProductColorValue(customProductCylindoMaterialCode, product.ACCENT_COLOR_STOCK_SKU2)
      : product.ACCENT_COLOR_STOCK_SKU2
    features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW2)
    features.push(featureValue.toUpperCase())
  }

  if (product?.ACCENT_COLOR_STOCK_SKU3) {
    const customProductCylindoMaterialCode = getCustomProductCylindoMaterialCd(product)
    const featureValue = customProductCylindoMaterialCode
      ? getCustomProductColorValue(customProductCylindoMaterialCode, product.ACCENT_COLOR_STOCK_SKU3)
      : product.ACCENT_COLOR_STOCK_SKU3
    features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW3)
    features.push(featureValue.toUpperCase())
  }

  return features
}

const getCustomAccentColor = (
  features: string[],
  accentPillow: ICylindoCustomFeatureCodes,
  cylindoMaterialCd: string | null,
  accentColors?: IAttributeSwatch,
) => {
  if (accentColors) {
    const featureValue = getCustomProductColorValue(cylindoMaterialCd, accentColors.swatches[0].option)
    if (features.length === 0) {
      features = [accentPillow, featureValue.toUpperCase()]
    } else {
      features.push(accentPillow)
      features.push(featureValue.toUpperCase())
    }
  }

  return features
}
/**
 * Currently we are not getting default features for custom skus from Cylindo, so
 * I added this logic to set the first color (from the group swatches, not from the product custom addons
 * since it could have more colors than the group) on BODY_COLOR as the product color, and
 * the same color for the product pillows, since is not ensured that the first color in ACCENT_COLOR
 * is the correct one.
 */
const getCustomProductFeatures = (options: IActiveOptions[], product?: IMorUnbxdProduct) => {
  let features: string[] = []
  const queryParams = queryString.parse(location.search)
  if (
    queryParams &&
    Object.keys(queryParams).length &&
    Object.keys(queryParams).some(param => allowedFeaturesOnQueryParams.includes(param))
  ) {
    for (const param in queryParams) {
      if (allowedFeaturesOnQueryParams.includes(param)) {
        if (param.toUpperCase() === ICustomAddons.ACCENT_COLOR) {
          features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW)
        } else if (param.toUpperCase() === ICustomAddons.ACCENT_COLOR2) {
          features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW2)
        } else if (param.toUpperCase() === ICustomAddons.ACCENT_COLOR3) {
          features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW3)
        } else {
          features.push(ICylindoCustomFeatureCodes.COLOR)
        }
        const queryParamValue = queryParams[param]?.toString().replace(HYPHEN_REGEX, ' ') || ''
        features.push(queryParamValue.toUpperCase())
      }
    }
  } else {
    const bodyColors = (product as IPDPProduct)?.groupSwatches?.find(swatch => swatch.code === ICustomAddons.BODY_COLOR)
    const accentColors = (product as IPDPProduct)?.groupSwatches?.find(
      swatch => swatch.code === ICustomAddons.ACCENT_COLOR,
    )
    const accentColors2 = (product as IPDPProduct)?.groupSwatches?.find(
      swatch => swatch.code === ICustomAddons.ACCENT_COLOR2,
    )
    const accentColors3 = (product as IPDPProduct)?.groupSwatches?.find(
      swatch => swatch.code === ICustomAddons.ACCENT_COLOR3,
    )
    const cylindoMaterialCd = product ? getCustomProductCylindoMaterialCd(product) : null
    if (bodyColors) {
      const featureValue = getCustomProductColorValue(cylindoMaterialCd, bodyColors.swatches[0].option)
      features = [ICylindoCustomFeatureCodes.COLOR, featureValue.toUpperCase()]

      if (options.find(op => op.code === ICustomAddons.ACCENT_COLOR)) {
        features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW)
        features.push(featureValue.toUpperCase())
      }

      if (options.find(op => op.code === ICustomAddons.ACCENT_COLOR2)) {
        features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW2)
        features.push(featureValue.toUpperCase())
      }

      if (options.find(op => op.code === ICustomAddons.ACCENT_COLOR3)) {
        features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW3)
        features.push(featureValue.toUpperCase())
      }
    } else {
      features = getCustomAccentColor(
        features,
        ICylindoCustomFeatureCodes.ACCENT_PILLOW,
        cylindoMaterialCd,
        accentColors,
      )
      features = getCustomAccentColor(
        features,
        ICylindoCustomFeatureCodes.ACCENT_PILLOW2,
        cylindoMaterialCd,
        accentColors2,
      )
      features = getCustomAccentColor(
        features,
        ICylindoCustomFeatureCodes.ACCENT_PILLOW3,
        cylindoMaterialCd,
        accentColors3,
      )
    }
  }

  features = features.concat(getStandardProductFeatures(options))

  return features
}

const getAlternateContent = (extraContent: IProductImage[]) => {
  return extraContent.map(content => {
    if (content.type === IProductImageType.VIDEO) {
      return {
        provider: 'direct',
        description: content.alt,
        poster: content.thumbnail,
        thumb: content.thumbnail || '/static/video-thumb.jpeg',
        sources: [{ src: content.url, type: 'video/mp4' }],
      } as IVideoAlternateContent
    }
    return { image: content.url, thumb: content.thumbnail } as IImageAlternateContent
  })
}

const isImagePlaceholder = (items: IProductImage[]) => {
  return items.find(item => item.url.includes('https://via.placeholder.com/300x200'))
}

export const getFrames = (options: IActiveOptions[]) => {
  let frames = [5, 29, 1, 9, 25, 17]
  const facingAttr = options.find(op => op.code === cylindoCodes.FACING)
  if (facingAttr?.value === 'Right') {
    frames = [29, 5, 1, 9, 25, 17]
  }

  return frames
}

const CylindoViewer = (props: ICylindoViewerProps) => {
  const { product, options, isFacingOptionChanging, onFinishInitViewer } = props

  const initViewer = (cylindoProductId: string, isCustom: boolean) => {
    console.log('Initialize Cylindo 360 viewer')
    // the opts object should hold the start properties of the 360 HD Viewer

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    const opts: ICylindoViewerOpts = {
      accountID: CYLINDO_ACCOUNT_ID,
      productCode: cylindoProductId,
      features: isCustom ? getCustomProductFeatures(options, product) : getStandardProductFeatures(options, product),
      containerID: `#${CYLINDO_VIEWER_ID}`,
      frames: getFrames(options),
      thumbPageSize: 6,
      thumbCount: 6,
      alternateContentZoom: false,
      tooltipAltImgText: null,
      thumbLocation: isMobile ? 'bottom' : 'left',
    }

    console.log('init viewer Features', opts.features)

    if (product.images.items && !isImagePlaceholder(product.images.items)) {
      opts.alternateContent = getAlternateContent(product.images.items)
    }

    // make sure the cylindo framework has been "installed"
    if (cylindo) {
      // do not instantiate the viewer until the framework reports ready.
      cylindo.on('ready', function() {
        // create the instance
        cylindo.viewer.create(opts)
      })
    }
  }

  const updateViewer = (viewerInstance: ICylindoViewerInstance, isCustom: boolean) => {
    console.log('Update Cylindo 360 viewer')
    viewerInstance.setFeatures(
      isCustom ? getCustomProductFeatures(options, product) : getStandardProductFeatures(options, product),
    )
    viewerInstance.updateAlternateContent([])
    if (product.images.items && !isImagePlaceholder(product.images.items)) {
      viewerInstance.updateAlternateContent(getAlternateContent(product.images.items))
    }
  }

  useEffect(() => {
    const cylindoProductId = product?.variants?.[0]?.cylindoProductId || product?.cylindoProductId
    const isCustom = product?.variants?.[0].CUSTOM_SKU === 'Y'

    if (cylindoProductId) {
      const viewerInstance: ICylindoViewerInstance = cylindo.viewer.getInstances()[
        `${cylindoProductId}_${CYLINDO_VIEWER_ID}`
      ]
      if (viewerInstance && !isFacingOptionChanging) {
        updateViewer(viewerInstance, isCustom)
      } else {
        initViewer(cylindoProductId, isCustom)
        onFinishInitViewer()
      }
    }
  }, [product.name])

  return <div id={CYLINDO_VIEWER_ID} />
}

export default CylindoViewer
